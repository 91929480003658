<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form class="ml-1 mr-1 mt-1">
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    label="Nombre: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.nombre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Apellido: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Apellido"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.apellido"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Series: "
                  >
                    <v-select
                      v-model="selected"
                      :dir="'ltr'"
                      multiple
                      label="numero"
                      :value.sync="series.id"
                      :reduce="series=> series.id"
                      :options="series"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="12">
                  <b-form-group
                    label="Usuario: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Usuario"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.correo"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col sm="12">
                  <b-form-group
                    label="Rol: "
                  >
                    <b-input-group>
                      <b-form-select
                        v-model="userData.role"
                        class="form-control"
                        :options="roles"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row class="text-center">
            <b-col
              md="12"
              class="mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Guardar</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class=""
                @click="cancelar"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <!-- modal -->
</template>

<script>
import store from '@/store/index'
import {
  BRow, BCol, BCard, BForm, BFormGroup, BInputGroup, BFormSelect, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
// eslint-disable-next-line import/no-unresolved
import generalData from '@fakedata'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: {
        nombre: '',
        apellido: '',
        correo: '',
        password: '',
        role: generalData.user.roles[0].value,
        user_serie: [],
      },
      roles: generalData.user.roles,
      series: [],
      selected: [],
    }
  },
  mounted() {
    // this.getUserByID()
    this.getInfoByID()
  },
  methods: {
    async Guardar() {
      for (let i = 0; i < this.selected.length; i += 1) {
        this.userData.user_serie.push(
          { serieId: this.selected[i] },
        )
      }
      const request = {
        url: `/api/users/${this.$route.params.id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.userData,
      }
      try {
        const respRoles = await store.dispatch('back/EXECUTE', request)
        if (respRoles.id) {
          this.sendMessage('Usuario registrado satisfactoriamente', 'EditIcon', 'success')
          this.$router.push({ name: 'usuario' })
        } else {
          this.sendMessage('Error', 'AlertCircleIcon', 'danger')
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.Guardar()
        }
      })
    },
    async getInfoByID() {
      let request = {
        url: `/api/users/${this.$route.params.id}`,
        method: 'GET',
      }
      let respRoles = await store.dispatch('back/EXECUTE', request)
      this.userData = respRoles
      this.userData.user_serie = []
      for (let i = 0; i < respRoles.series.length; i += 1) {
        this.selected.push(respRoles.series[i].serie.id)
      }
      console.log(this.selected)
      console.log(respRoles)
      request = {
        url: '/api/series/',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      respRoles = await store.dispatch('back/EXECUTE', request)
      this.series = respRoles
      console.log(this.userData)
    },
    async getUserByID() {
      const request = {
        url: `/api/users/${this.$route.params.id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const respRoles = await store.dispatch('back/EXECUTE', request)
      this.userData = respRoles
      for (let i = 0; i < respRoles.series.length; i += 1) {
        this.selected.push(respRoles.series[i].id)
      }
      console.log(this.selected)
    },
    cancelar() {
      this.$router.push({ name: 'usuario' })
    },
    sendMessage(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
